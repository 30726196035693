.drawer-module {
    @apply w-full;
}

.drawer-container {
    @apply grid gap-8 lg:grid-cols-3;
}

.drawer-content {
    @apply lg:col-span-1;
}

.drawer-media {
    @apply lg:col-span-2 relative aspect-square rounded-2xl overflow-hidden bg-gray-100;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.drawer-media.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Media container for crossfade effect */
.drawer-media-container {
    @apply lg:col-span-2 relative aspect-square rounded-2xl overflow-hidden;
}

/* Fade animation classes */
.drawer-media.fade-in {
    animation: fadeIn 0.5s ease forwards;
}

.drawer-media.fade-out {
    animation: fadeOut 0.5s ease forwards;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.drawer-media img,
.drawer-media video {
    @apply absolute inset-0 w-full h-full object-cover;
}

.video-placeholder {
    @apply absolute inset-0 w-full h-full bg-cover bg-center flex items-center justify-center;
    opacity: 1 !important;
    transform: none !important;
}

.video-placeholder.visible {
    opacity: 1;
}

.video-loading-indicator {
    @apply bg-black/30 rounded-full p-3 flex items-center justify-center;
}

.drawer-title {
    @apply text-4xl md:text-5xl font-headline text-black mb-8;
}

.drawer-list {
    @apply flex flex-col gap-2;
}

.drawer-item {
    @apply border-t border-gray-200 last:border-b;
}

.drawer-header {
    @apply flex items-center justify-between py-6 w-full text-left cursor-pointer;
}

.drawer-header h3 {
    @apply text-xl font-headline text-black pr-8;
}

.drawer-icon {
    @apply w-6 h-6 text-black transition-transform duration-300;
}

.drawer-icon.open {
    transform: rotate(45deg);
}

.drawer-body {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
}

.drawer-content-wrapper {
    @apply flex flex-col gap-6;
}

.drawer-description {
    @apply text-gray-600;
}

/* Mobile specific styles */
@media (max-width: 1023px) {
    .drawer-media-container {
        @apply relative aspect-square rounded-2xl overflow-hidden mt-4;
    }

    .drawer-media {
        @apply relative aspect-square rounded-2xl overflow-hidden;
        opacity: 1;
        transform: none;
    }

    .drawer-media.fade-in {
        animation: fadeIn 0.5s ease forwards;
    }

    .drawer-media.fade-out {
        animation: fadeOut 0.5s ease forwards;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .drawer-body {
        @apply flex flex-col gap-4;
    }
}