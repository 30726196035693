.spotlight-gradient {
    @apply absolute inset-0;
}

/* Mobile gradient (bottom to top) */
.spotlight-gradient::before {
    content: '';
    @apply absolute inset-0;
    background: linear-gradient(to bottom, transparent 0%, var(--gradient-color) 100%);
}

/* Desktop gradient (left to right) */
@media (min-width: 1024px) {
    .spotlight-gradient::before {
        background: linear-gradient(to right, var(--gradient-color) 0%, transparent 70%);
    }
}