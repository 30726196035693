@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Moderat';
        src: url('../public/assets/fonts/Moderat-Semibold.woff2') format('woff2'),
            /* Modern Browsers */
            url('../public/assets/fonts/Moderat-Semibold.woff') format('woff');
        /* Older Browsers */
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Isola';
        src: url('../public/assets/fonts/Isola-Regular.woff2') format('woff2'),
            url('../public/assets/fonts/Isola-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    h1 {
        @apply text-[40px] md:text-[48px];
        font-family: 'Moderat',
            "Helvetica Neue",
            Helvetica,
            Arial,
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h2 {
        @apply text-[20px] md:text-[28px];
        font-family: 'Moderat',
            "Helvetica Neue",
            Helvetica,
            Arial,
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h3 {
        @apply text-[16px] md:text-[18px];
        font-family: 'Moderat',
            "Helvetica Neue",
            Helvetica,
            Arial,
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h4 {
        @apply text-[14px] md:text-[16px];
        font-family: 'Moderat',
            "Helvetica Neue",
            Helvetica,
            Arial,
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h5 {
        @apply text-[12px] md:text-[14px];
        font-family: 'Moderat',
            "Helvetica Neue",
            Helvetica,
            Arial,
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

body {
    margin: 0;
    font-size: 16px;
    font-family: 'Isola',
        "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

heading {
    margin: 0;
    font-size: 16px;
    font-family: 'Moderat',
        "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@layer utilities {
    .pb-safe {
        padding-bottom: env(safe-area-inset-bottom);
    }

    .mb-safe {
        margin-bottom: env(safe-area-inset-bottom);
    }
}