.comparison-module {
    @apply w-full bg-white py-16;
}

.comparison-header {
    @apply text-start mb-12;
}

.comparison-title {
    @apply text-4xl md:text-5xl font-headline text-black mb-4;
}

.comparison-subtitle {
    @apply text-lg text-gray-600 max-w-2xl;
}

.comparison-table {
    @apply w-full border-collapse relative table-fixed;
}

.comparison-table colgroup col:first-child {
    width: 50%;
}

.comparison-table colgroup col {
    width: 25%;
}

/* Header row styles */
.comparison-table thead tr {
    @apply border-0 hover:bg-transparent;
}

/* Body row styles */
.comparison-table tbody tr {
    @apply border-t border-gray-400 transition-colors hover:bg-gray-50;
}

/* Kibu column highlight */
.kibu-column-highlight {
    @apply absolute top-0 bottom-0 rounded-2xl;
    left: 50%;
    width: 25%;
    background: linear-gradient(to bottom, rgba(51, 71, 255, 0.1), rgba(51, 71, 255, 0.05));
    border: 2px solid theme('colors.blue');
    z-index: 10;
    pointer-events: none;
}

.comparison-feature {
    @apply py-6 px-2 md:px-6;
}

.feature-header {
    @apply flex items-center gap-4 mb-2;
}

.feature-icon {
    @apply w-8 h-8 text-blue;
}

.feature-title {
    @apply text-lg font-headline text-blue;
}

.feature-description {
    @apply text-gray-600 text-sm md:text-base;
}

.comparison-check {
    @apply py-6 px-4 md:px-6 text-center relative text-xl;
}

.check-icon {
    @apply w-5 h-5 relative;
    z-index: 11;
}

.check-icon.has {
    @apply text-white;
}

.check-icon.hasnt {
    @apply text-gray-600;
}

.check-circle {
    @apply flex w-10 h-10 items-center justify-center rounded-full mx-auto;
}

.check-circle.active {
    @apply bg-blue;
}

.check-circle.inactive {
    @apply border border-gray-600;
}

/* Mobile adjustments */
@media (max-width: 768px) {

    .comparison-check {
        @apply px-1;
    }

    .feature-header {
        @apply gap-2;
    }

    .feature-icon {
        @apply w-6 h-6;
    }

    .feature-title {
        @apply text-lg;
    }

    .feature-description {
        @apply hidden;
    }

    .check-circle {
        @apply w-8 h-8;
    }

    .check-icon {
        @apply w-4 h-4;
    }

    .comparison-feature {
        @apply py-4 px-2;
    }
}