.standard_container {
  @apply flex flex-col md:flex-row gap-4 md:gap-16 justify-between w-[87.5%] md:max-w-[1400px] md:min-w-[980px] md:w-[87.5%];
}

.module_container {
  @apply mx-auto w-[87.5%] md:max-w-[1400px] md:w-[87.5%] mt-12 mb-12 overflow-visible;
}

@media (max-width: 768px) {
  .hidden-on-mobile {
    display: none;
  }
}

.fade-in {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}