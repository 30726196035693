/* Blog Markdown Content Styling */
.blog-content {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;
    color: #333;
}

.blog-content h1 {
    font-size: 3rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: #1a202c;
    line-height: 1.2;
}

.blog-content h2 {
    font-size: 2rem;
    margin-top: 2.75rem;
    margin-bottom: 0.75rem;
    font-weight: 600;
    color: #1a202c;
    line-height: 1.2;
}

.blog-content h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #1a202c;
}

.blog-content p {
    margin-bottom: 1.5rem;
}

.blog-content a {
    @apply text-primary font-headline border-b-2 border-dotted border-primary;
    text-decoration: none;
    transition: opacity 0.2s;
}

.blog-content a:hover {
    opacity: 0.5;
}

.blog-content ul,
.blog-content ol {
    margin-bottom: 1.5rem;
    padding-left: 2rem;
}

.blog-content ul li,
.blog-content ol li {
    margin-bottom: 0.5rem;
}

.blog-content blockquote {
    border-left: 4px solid #e2e8f0;
    padding-left: 1rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.5rem;
    font-style: italic;
    color: #4a5568;
}

.blog-content img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    margin: 2rem 0;
}

.blog-content pre {
    background-color: #f7fafc;
    padding: 1rem;
    border-radius: 0.5rem;
    overflow-x: auto;
    margin-bottom: 1.5rem;
}

.blog-content code {
    background-color: #f7fafc;
    padding: 0.2rem 0.4rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

/* Blog Tags */
.blog-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 2rem;
}

.blog-tag {
    background-color: #e2e8f0;
    color: #4a5568;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    transition: background-color 0.2s;
}

.blog-tag:hover {
    background-color: #cbd5e0;
}

/* Author Bio */
.author-bio {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    background-color: #f7fafc;
    border-radius: 0.5rem;
    margin: 2rem 0;
}

.author-avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 9999px;
    object-fit: cover;
    margin-right: 1rem;
}

.author-info h4 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.author-info p {
    font-size: 0.875rem;
    color: #4a5568;
    margin-bottom: 0;
}

/* Related Articles */
.related-articles {
    margin-top: 3rem;
}

.related-articles h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.related-articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}

/* Blog Image Hover Animation */
.blog-post-item {
    transition: transform 0.2s ease-out;
}

.blog-post-item:hover {
    transform: translateY(-10px);
}

.blog-image-container {
    position: relative;
    overflow: hidden;
}

.blog-image-hover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .blog-content h1 {
        font-size: 2rem;
    }

    .blog-content h2 {
        font-size: 1.75rem;
    }

    .blog-content h3 {
        font-size: 1.5rem;
    }

    .author-bio {
        flex-direction: column;
        text-align: center;
    }

    .author-avatar {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .related-articles-grid {
        grid-template-columns: 1fr;
    }
}