.scrolling-gallery {
    @apply w-full overflow-hidden relative;
}

.scrolling-gallery-container {
    @apply flex cursor-grab overflow-x-auto;
    /* Hide scrollbar but keep functionality */
    scrollbar-width: none;
    -ms-overflow-style: none;
    /* Ensure content doesn't wrap */
    white-space: nowrap;
    /* Force horizontal layout */
    flex-wrap: nowrap;
    /* Enable touch scrolling on mobile */
    -webkit-overflow-scrolling: touch;
    /* Prevent text selection while dragging */
    user-select: none;
    /* Allow both vertical and horizontal touch actions */
    touch-action: pan-x pan-y;
}

.scrolling-gallery-container::-webkit-scrollbar {
    display: none;
}

/* When actively dragging */
.scrolling-gallery-container.dragging {
    @apply cursor-grabbing;
}

/* Section styles */
.gallery-section {
    @apply flex-none relative;
    height: 35rem;
    /* Mobile height */
}

.gallery-section.start {
    width: 50vw;
}

/* Gallery images section */
.gallery-section.images {
    @apply flex flex-col flex-wrap items-start content-start relative;
    margin: 0 0.4375rem;
}

.gallery-image {
    @apply relative overflow-hidden rounded-2xl;
    transition: transform 1s ease-out;
    transform: scale(0.90);
}

.gallery-image.in-view {
    transform: scale(1);
}

.gallery-image-inner {
    @apply relative w-full;
    padding-bottom: 100%;
    /* Square aspect ratio */
}

.gallery-image img {
    @apply absolute inset-0 w-full h-full object-cover;
}

/* Media container styles */
.media-container {
    @apply absolute inset-0 w-full h-full;
}

/* Placeholder styles for images and videos */
.image-placeholder,
.video-placeholder {
    @apply absolute inset-0 w-full h-full bg-gray-100 flex items-center justify-center;
    background-size: cover;
    background-position: center;
}

/* Loading indicator styles */
.image-loading-indicator,
.video-loading-indicator {
    @apply bg-black/30 rounded-full p-3 flex items-center justify-center;
}

/* Instagram handle overlay */
.gallery-image-overlay {
    @apply absolute bottom-0 left-0 right-0 p-2 pl-3;
}

.gallery-image-handle {
    @apply text-white text-xs uppercase tracking-widest;
    font-size: 0.5rem;
    line-height: 1.125rem;
    letter-spacing: 0.125rem;
    left: 1.5375rem;
    bottom: 0rem;
}

/* Community section styles */
.community-section {
    @apply flex flex-col justify-center items-start p-6 pr-4 md:p-12 md:pr-0 h-full;
}

.community-section-right {
    @apply flex flex-col justify-center items-start p-6 pr-4 md:p-12 md:pr-12 h-full;
}

.community-title {
    @apply text-4xl md:text-5xl font-headline mb-6 whitespace-normal text-black;
}

.community-title .highlight {
    @apply text-5xl md:text-6xl block my-2 text-black;
}

.community-description {
    @apply text-lg mb-8 whitespace-normal max-w-[15rem] text-black;
}

.community-cta {
    @apply text-orange underline underline-offset-4 hover:text-orange/80 transition-colors uppercase tracking-wider text-sm;
}

/* Desktop adjustments */
@media only screen and (min-width: 768px) {
    .gallery-section {
        height: 48.875rem;
    }

    .gallery-section.images {
        margin: 0 1.25rem;
    }

    .gallery-image-handle {
        font-size: 0.675rem;
        letter-spacing: 0.1875rem;
        left: 3%;
        bottom: 2%;
    }
}