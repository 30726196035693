/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.text-gradient {
    background: linear-gradient(to right,
            #ff6b6b,
            #4ecdc4,
            #45b7d1,
            #96e6a1,
            #ff6b6b);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 500% auto;
    animation: gradient 3s linear infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.animate-gradient-text {
    transition: all 0.2s ease;
}

.animate-gradient-text:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 0.9);
}